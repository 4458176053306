.manage-categories-container {
  padding: 50px;
}

.manage-categories-container h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.categories-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.category-col {
  display: flex;
  justify-content: center;
}

.category-card {
  width: 100%;
  max-width: 300px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.category-card:hover {
  transform: translateY(-5px);
}

.add-category-form {
  display: flex;
  justify-content: flex-start; /* Align form to the top left of the column */
  gap: 10px;
  margin-top: 20px;
}

.add-category-form input {
  width: 250px;
}

.add-category-form button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #34a3e7;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  transition: background 0.2s ease-in-out;
}

.add-category-form button:hover {
  background-color: #107ec7;
}
