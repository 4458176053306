.manage-stock-container {
    padding: 20px;
  }
  
  .manage-stock-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .back-button {
    margin-bottom: 20px;
  }
  