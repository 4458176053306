.add-item-container {
    margin-top: 50px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form-group label {
    font-weight: bold;
  }
  
  .form-control {
    margin-bottom: 15px;
  }

  .image-preview {
  display: flex;
  flex-wrap: wrap;
}
.preview-img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 100px;
  height: auto;
  object-fit: cover;
  margin-right: 10px;
}
