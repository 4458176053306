.dashboard-container-fluid {
    padding: 0; /* Remove any padding */
    width: 100%; /* Full width */
  }
  
  .dashboard-title {
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .dashboard-options {
    display: flex;
    justify-content: space-between; /* Space out the columns evenly */
    flex-wrap: wrap;
  }
  
  .dashboard-card {
    text-align: center;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease-in-out;
    min-width: 300px;
    max-width: 350px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .dashboard-card:hover {
    transform: translateY(-5px);
  }
  
  .dashboard-card button {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    background-color: #34a3e7; /* Light blue */
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    transition: background 0.2s ease-in-out;
    cursor: pointer; /* Added pointer cursor */
  }
  
  .dashboard-card button:hover {
    background-color: #107ec7; /* Slightly darker blue */
    cursor: pointer; /* Ensures pointer cursor on hover */
  }
  