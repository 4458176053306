.edit-item-list-container {
    padding: 20px;
  }
  
  .edit-item-list-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .table {
    width: 100%;
  }
  
  .table th,
  .table td {
    text-align: center;
    vertical-align: middle;
  }
  
  .edit-button {
    margin-right: 10px;
  }
  
  .remove-button {
    margin-left: 10px;
  }
  